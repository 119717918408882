@import "./global.scss";
.app {
  height: 100vh;

  .sections {
    width: 100%;

    @include mobile {
      top: 50px;
    }
    background-color: rgb(255, 255, 255);
    position: relative;
    top: 70px;

    > * {
      width: 100vw;
    }
  }
}
