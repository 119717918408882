@import "../../global.scss";

.title {
  background-color: rgb(5, 5, 5);
  font-size: 30px;
  color: aliceblue;
  padding: 20px 20px;
  margin-top: 5px;
  display: flex;
  justify-content: center;

  @include mobile {
    padding: 7px;
    font-size: 17px;
    margin-top: 0px;
    color: rgb(255, 255, 255);
  }
}

.project_name {
  background-color: rgbrgb(5, 5, 5);
  font-size: 30px;
  color: aliceblue;
  padding: 20px 20px;
  margin-top: 10px;

  @include mobile {
    padding: 7px;
    font-size: 17px;
    margin-top: 5px;
    color: rgb(255, 255, 255);
  }
}

.footer {
  background-color: rgb(32, 30, 30);
  font-size: 30px;
  color: rgb(177, 181, 184);
  padding: 20px 20px;
  margin-top: 0px;
  display: flex;
  justify-content: right;
  img {
    width: 40px;
    height: 40px;
    margin: 0px 3px;
  }

  @include mobile {
    padding: 7px;
    font-size: 17px;
    margin-top: 0px;
    color: rgb(255, 255, 255);
    img {
      width: 20px;
      height: 20px;
      margin: 0px 3px;
    }
  }
}

.works {
  background-color: rgb(5, 5, 5);
  display: flex;
  align-items: center;
  justify-content: center;

  .slider {
    margin-top: 15px;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        padding-left: 20px;
        margin-top: 3px;
        margin-bottom: 50px;
        width: 900px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          flex-direction: column;
          width: 100%;
          margin: 5px 0px;
        }

        .left {
          flex: 0.5;
          height: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          overflow: hidden;

          @include mobile {
            margin-bottom: 30px;
          }

          img {
            width: 100vw;
          }
        }

        .right {
          flex: 0.5;
          height: 90%;
          display: flex;
          margin-top: 40px;

          @include mobile {
            flex: 7;
            margin-top: 0px;
          }

          .rightContainer {
            margin-left: 50px;
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mobile {
              margin-left: 0px;
            }

            h2 {
              font-size: 25px;
              color: rgb(193, 196, 200);
            }

            p {
              margin-top: 0px;
              font-family: Palatino, URW Palladio L, serif;
            }

            ul.bullets {
              margin-top: 1px;
              list-style-type: none;

              @include mobile {
                padding: 0;
              }
              li {
                word-spacing: 1px;
                vertical-align: top;
                margin: 3px 1px;
                font-size: 20px;
                padding: 3px;
                color: rgb(240, 236, 230);

                @include mobile {
                  margin: 0px 0px;
                  font-size: 14px;
                  padding: 2px;
                }
              }
            }

            ul.tags {
              text-align: center;
              margin-top: 15px;

              @include mobile {
                padding: 0;
                margin-top: 40px;
                margin-bottom: 30px;
              }

              li {
                display: inline-block;
                vertical-align: top;
                margin: 5px 5px;
                font-size: 15px;
                color: rgb(25, 74, 124);
                background-color: rgb(224, 238, 245);
                padding: 3px;
                border-radius: 2px;

                @include mobile {
                  margin: 3px 3px;
                  font-size: 11px;
                  padding: 2px;
                }
              }
            }
          }
          .btn-dark {
            background-color: #424141; /* Change to your desired dark color */
            color: #f7f5f5; /* Change to your desired text color */
            border: 2px solid #ccb9b9;
            text-align: center;
            max-width: 80%;
            margin-left: 40px;
            margin-top: 20px;
            margin-bottom: 130px;
            font-size: 20px;
            padding: 5px;
            font-family: system-ui;
            text-decoration: none;
            @include mobile {
              margin-left: 90px;
              margin-top: 0px;
              max-width: 50%;
              margin-bottom: 75px;
              padding: 3px;
              font-size: 16px;
            }
          }

          .btn-dark:hover {
            background-color: #212529; /* Change to your desired hover color */
            color: #ffffff; /* Change to your desired hover text color */
          }
        }
      }
    }
  }
}
