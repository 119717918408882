@import "../../global.scss";

.menu {
    width: 300px; 
    height: 100vh;
    background-color: $mainColor;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;

    @include mobile {
        width: 150px;
        }

    &.active {
        right: 0;
        box-shadow: 10px 10px 10px 15px rgb(21, 111, 119);
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: white;
        width: 60%;
        font-family: Monaco;

        @include mobile {
            font-size: 20px;
            }

        li {
            margin-bottom: 25px;
            font-family: inherit;
            a {
                font-size: inherit;
                color: inherit;
                text-decoration: none;
                font-family: inherit;
            }

            &:hover {
                font-weight: 500;
            }
        }

        .resume {
            text-decoration: none;
            background-color: $mainColor;
            color: rgb(135, 190, 203);
            border: 0.5px solid rgb(135, 190, 203);
            display:block;    
            padding: 4px;
            width: 120px;
            font-family: inherit;

            @include mobile {
                width: 80px;
                }
        }
    }
}