@import "../../global.scss";

.portfolio {
    background-color:aliceblue;

    .portfolio_title {
        color: darkblue;
        margin: 20px 20px;
        font-family: arial;
        font-size: 30px;

        @include mobile {
            margin: 10px 10px;
            font-size: 20px;
        }
    }

    ul {
         padding: 15px;
         text-align: center;

        li {
            display: inline-block;
            vertical-align: top;
            margin-left: 25px;
            margin-top: 25px;
            font-size: 40px;
            color: rgb(25, 74, 124);
            background-color: rgb(195, 226, 241);
            padding: 10px;
            border-radius: 7px;
            font-family: Optima, sans-serif;

            @include mobile {
                text-align: center;
                margin-top: 15px;
                font-size: 18px;
                padding: 7px;
                margin-left: 15px;
                border-radius: 6px;
            }

            &:hover {
                color: black;
                background-color: aliceblue;
            }
        }
    }

    .skills {
        margin-top: 10px;

        @include mobile {
            margin-top: 0vw;
        }
        
        ul {
            img {
                font-size: 40px;
            }
            li{
                display: inline-block;
                margin: 10px;
                background-color: aliceblue;
                .skill1 {
                    width: 90px;
                    font-size: 14px;
                    color: blue;

                    @include mobile {
                        font-size: 34px;
                        width: 35px;
                    }
                }
            }
            }
        }
    
}