@import "../../global.scss";

.intro {
  background-image: url("../../../public/assets/sky.jpeg");
  background-size: cover;
  background-position-y: -20vw;
  height: 100vh;

  display: flex;

  @include mobile {
    background-position-y: -67vw;
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.3;
    overflow: hidden;

    @include mobile {
      flex: 0.7;
    }

    .imgContainer {
      width: fit-content;
      height: min-content;
      margin-top: 270px;
      margin-left: 170px;

      @include mobile {
        flex: 0.3;
        width: fit-content;
        height: fit-content;
        margin-top: 50px;
        margin-left: 0px;
      }
      align-items: flex-start;
      img {
        height: 50%;
        border-radius: 15%;
        border-color: rgb(17, 24, 59);
        border-style: inset;

        @include mobile {
          align-items: center;
          width: 30vw;
          border-radius: 50%;
          border-color: rgb(85, 99, 171);
          border-style: inset;
          margin-left: 16vw;
        }
      }
    }

    .connectOn {
      margin-top: 100px;
      margin-left: 29px;
      @include mobile {
        margin-top: 4px;
        margin-left: -10vw;
      }

      ul {
        a {
          font-size: 40px;
        }
        li {
          display: inline-block;
          margin: 20px;
          .connect_icons {
            width: 90px;
            font-size: 54px;
            color: rgb(92, 121, 195);

            @include mobile {
              font-size: 44px;
              width: 45px;
            }
          }

          .connect_icons2 {
            width: 90px;
            font-size: 45px;
            color: rgb(140, 140, 140);

            @include mobile {
              font-size: 40px;
              width: 45px;
            }
          }

          .connect_icons3 {
            width: 50px;
            font-size: 35px;
            color: black;

            @include mobile {
              font-size: 40px;
              width: 45px;
            }
          }
        }
      }
    }
  }

  .right {
    flex: 0.73;
    overflow: hidden;

    @include mobile {
      flex: 1;
    }

    .wrapper {
      width: 75%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: Arial;
      margin-top: 0px;

      @include mobile {
        font-size: 15px;
        padding-left: 20px;
        width: 90%;
        height: 90%;
        justify-content: flex-start;
      }

      h1 {
        font-size: 60px;
        color: #999fe4;
        margin: 10px 0px;
        display: inline;
        font-family: Arial, sans-serif;

        @include mobile {
          font-size: 22px;
        }
      }

      .intro1 {
        font-size: 25px;
        color: rgb(118, 145, 195);
        margin-top: 20px;
        font-family: Andale Mono Narrow;

        @include mobile {
          margin-top: 1px;
          font-size: 19px;
        }
      }

      h3 {
        font-size: 30px;
      }

      p {
        font-size: 25px;
        font-family: inherit;
        color: rgb(226, 229, 234);
        margin-top: 20px;

        @include mobile {
          margin-top: 10px;
          font-size: 18px;
          color: azure;
        }
      }

      .college {
        display: inline;
        text-decoration: none;
        font-family: inherit;
        color: rgb(89, 158, 181);
      }

      .interests {
        @include mobile {
          color: rgb(82, 153, 172);
        }
      }
    }

    .arrow {
      position: absolute;
      bottom: 1px;
      left: 50%;

      @include mobile {
        font-size: 30px;
        bottom: 50px;
      }

      .icon {
        font-size: 50px;

        @include mobile {
          font-size: 30px;
        }

        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
